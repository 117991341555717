import * as React from 'react';
import './../styles/ErrorPage.css';

export default function NotFoundPage() {
    return(
        <div className='error_container'>
            <h1>
                Страница не найдена!
            </h1>
        </div>
    );
}
