// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/ProximaNova-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../fonts/ProximaNova-Semibold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Proxima Nova';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Proxima Nova Semibold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n  font-weight: bold;\n  font-style: normal;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Proxima Nova', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #ebf0f6;\n}\n", "",{"version":3,"sources":["webpack://src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,+DAA+D;EAC/D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,+DAAgE;EAChE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,uCAAuC;EACvC,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;AAC3B","sourcesContent":["@font-face {\n  font-family: 'Proxima Nova';\n  src: url('../fonts/ProximaNova-Regular.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Proxima Nova Semibold';\n  src: url('../fonts/ProximaNova-Semibold.ttf') format('truetype');\n  font-weight: bold;\n  font-style: normal;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Proxima Nova', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #ebf0f6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
