import * as React from 'react';
import '../styles/Home.css';
import {useLoaderData} from "react-router-dom";
import {auth, getBanks} from "../api/api";
import sendErrorToBot from '../api/sendError';
import chevron from '../assets/BackGrayChevron.svg';
import ErrorPage from "./ErrorPage";

export async function homeLoader() {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('client_token');
    const returnUrl = url.searchParams.get('return_url');
    if (token) {
        localStorage.setItem('client_token', token!);
        if (returnUrl) {
            localStorage.setItem('return_url', returnUrl);
        }
    } else {
        const localToken = localStorage.getItem('client_token');
        return !!localToken
            ? window.location.href = `/?client_token=${localToken}`
            : { noAccess: true };
    }
    const res = await auth({token});
    if (!res || res.error) {
        sendErrorToBot('🟡FOREIGN CARD INFO',
            url.href + `${res && res.error ? `\nError: ${JSON.stringify(res.error)}` : ''}`
            + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
        return { error: true };
    }
    const responseBanks = await getBanks();
    if (!responseBanks || !responseBanks.result || responseBanks.result.length === 0) {
        sendErrorToBot('🟡FOREIGN CARD INFO BANKS',
            window.location.href + `${responseBanks && responseBanks.error ? `\nError: ${JSON.stringify(responseBanks.error)}` : ''}`
            + `${responseBanks && responseBanks.result ? `\nResult: ${JSON.stringify(responseBanks.result)}` : ''}`
            + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
        return { error: true };
    }
    return { banks: responseBanks.result, token };
}

function HomePage() {
    const loaderData = useLoaderData();
    const isError = !!(loaderData && (loaderData as any).error);
    if (isError) {
        return ErrorPage();
    }
    const isNoAccess = !!(loaderData && (loaderData as any).noAccess);
    if (isNoAccess) {
        return ErrorPage('У вас нет доступа к данной странице!');
    }
    const token = loaderData && (loaderData as any).token;

    return (
        <div className='foreign-card-container' style={{paddingBottom: '32px'}}>
            Зарубежная карта – это эксклюзивный сервис для премиальных клиентов Банка, позволяющий оплачивать услуги и покупки за границей.
            {loaderData && (loaderData as any).banks && (loaderData as any).banks.map((bank: any, i: number) => bank.id && bank.name && <div
                key={i}
                className='foreign-card-container__country-block'
                onClick={() => {
                    let url = bank.link;
                    if (!url) {
                        return;
                    }
                    if (token) {
                        url += `?client_token=${token}`;
                        const returnUrl = localStorage.getItem('return_url');
                        if (returnUrl) {
                            url += `&return_url=${returnUrl}`;
                        }
                    }
                    window.location.href = url;
                }}
            >
                {bank.name}
                <img src={chevron} alt='chevron icon'/>
            </div>)}
            Сообщаем, что, нажимая на «Карта банка Таджикистан» и «Карта банка Кыргызстан», вы переходите на сайты поставщика выбранной вами услуги и самостоятельно несете ответственность за Ваше дальнейшее взаимодействие с поставщиком выбранной услуги
        </div>
    );
}

export default HomePage;
