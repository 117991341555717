import sendErrorToBot from "./sendError";

const {REACT_APP_BACKEND_HOST, REACT_APP_BACKEND_KEY} = process.env;

export async function auth(params: {
    token?: string;
    login?: string;
    password?: string;
}) {
    let url = `${REACT_APP_BACKEND_HOST}/api/client/v1/auth?`;
    if (params.token) {
        url += `client_token=${params.token}`;
    } else {
        url += `login=${params.login}&password=${params.password}`;
    }
    try {
        const response = await fetch(url, {
            headers: {
                accepts: 'application/json',
                Authorization: `Basic ${REACT_APP_BACKEND_KEY}`
            },
            method: 'GET'
        });
        if (response.status >= 300) {
            await sendErrorToBot('🟡FOREIGN CARD INFO AUTH',
                window.location.href + `\n${response.status}`);
            const res = await response.json();
            return {
                error: res && res.error ? res.error : response.statusText || response.status || 'error'
            }
        }
        const res = await response.json();
        if (res && res.result && res.result.access_token) {
            localStorage.setItem('access_token', res.result.access_token);
            return {
                result: true
            }
        }
        return {
            error: JSON.stringify(res.result) || 'Ошибка получение токена'
        };
    }
    catch (error) {
        return {
            error: error
        };
    }
}

export async function getBanks(isSingle?: boolean): Promise<any> {
    try {
        const token = localStorage.getItem('access_token');
        const response =  await fetch(`${REACT_APP_BACKEND_HOST}/api/client/v1/banks`, {
            headers: {
                accepts: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'GET'
        });
        if (response.status >= 300) {
            if (response.status === 401 && !isSingle) {
                const token = localStorage.getItem('client_token');
                if (token) {
                    await auth({token});
                    return await getBanks(true);
                }
                const password = localStorage.getItem('manager_pass');
                const login = localStorage.getItem('manager_login');
                if (password && login) {
                    await auth({password, login});
                    return await getBanks(true);
                }
                return ;
            }
            await sendErrorToBot('🟡FOREIGN CARD INFO GET BANKS',
                window.location.href + `\n${response.status}`);
            return {
                error: response.statusText
            }
        }
        return await response.json();
    }
    catch (error) {
        return {
            error: error
        };
    }
}
